import { fetchApi } from '../functions/apiCalls';
import { apiUrl } from '../constants/constantsGlobal';

const tomtomSearch = async (query, params) => fetchApi(
  `${apiUrl}addressmanager/tomtomSearch?query=${encodeURIComponent(
    query,
  )}&params=${encodeURIComponent(
    params,
  )}`,
  'GET',
  { credentials: 'include' },
)
  .then((response) => response.json())
  .then((resp) => resp)
  .catch((e) => {
    console.error(e);
    return undefined;
  });

const tomtomReverseGeocode = async (lat, lng, params) => fetchApi(
  `${apiUrl}calltakemanager/tomtomReverseGeocode?lat=${lat}&lng=${lng}&params=${encodeURIComponent(
    params,
  )}`,
  'GET',
  { credentials: 'include' },
)
  .then((response) => response.json())
  .then((resp) => resp)
  .catch((e) => {
    console.error(e);
    return undefined;
  });

const tomtomSearchApi = (query, lat, lng, radius) => tomtomSearch(query, `&radius=${radius * 1000}${lat && lng ? `&lat=${lat}&lon=${lng}` : ''}`);

const tomtomSuburbSearchApi = (query, lat, lng, radius) => tomtomSearch(query, `&radius=${radius * 1000}${lat && lng ? `&lat=${lat}&lon=${lng}` : ''}&entityTypeSet=MunicipalitySubdivision`);

export const getSearchedPlaces = async (...args) => {
  const json = await tomtomSearchApi(...args);
  return json;
};

export const getSuburbs = async (...args) => {
  const json = await tomtomSuburbSearchApi(...args);
  return json;
};

export const getReverseGeocodedPlace = async (lat, lng) => {
  const json = await tomtomReverseGeocode(lat, lng, '&returnSpeedLimit=false&returnRoadUse=false&allowFreeformNewLine=false&returnMatchType=false&view=Unified');
  return json;
};

export const checkOutagesInSuburb = async (suburb) => fetchApi(
  `${apiUrl}custommanager_cp_mdt/checkOutagesInSuburb?suburb=${suburb}`,
  'GET',
  { credentials: 'include' },
).then((res) => {
  if (res.status >= 200 && res.status < 300) {
    return true;
  }
  return false;
});
