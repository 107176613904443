// Use this for dev on localhost, comment this when committing.
//
// const urlWithoutPrefix = () => {
//   let temp = `${process.env.REACT_APP_API_BASE}/forcelink/`;
//   if (process.env.REACT_APP_API_BASE.includes('core')) {
//     temp = process.env.REACT_APP_API_BASE.concat('/forcelink/',
//       process.env.REACT_APP_API_BASE.split('.')[0], '/core/');
//   } else if (!process.env.REACT_APP_API_BASE.includes('cpt-prod')) {
//     temp = process.env.REACT_APP_API_BASE.concat('/forcelink/za4/');
//   }
//   return temp;
// };
//

// This must be uncommented before committing.
//
const urlWithoutPrefix = () => {
  let temp;
  //  temp = 'cpdev.forcelink.net/forcelink/';
  if (process.env.REACT_APP_API_BASE.includes('core')) {
    temp = process.env.REACT_APP_API_BASE.concat('/forcelink/',
      process.env.REACT_APP_API_BASE.split('-')[0], '/core/');
  } else if (window.location.hostname.includes('www.')) {
    temp = `www.${process.env.REACT_APP_API_BASE.concat('/forcelink/za4/')}`;
  } else {
    temp = process.env.REACT_APP_API_BASE.concat('/forcelink/za4/');
  }
  return temp;
};
//

export const apiBaseUrlFunc = () => `https://${urlWithoutPrefix()}`;
export const apiUrlFunc = () => `${urlWithoutPrefix()}rest/`;

export const apiBaseUrl = urlWithoutPrefix();
export const apiUrl = `${urlWithoutPrefix()}rest/`;
export const apiUrlWithHttps = `https://${urlWithoutPrefix()}rest/`;

const mapboxToken = 'pk.eyJ1IjoiY2ptZXllcmFjdW1lbiIsImEiOiJja2k4cnZjejIwOHFnMndwanI3dnJuZDl0In0.Pj0CciQkmYaenHsRIutO3Q';
const mapboxAttribution = 'Map data © <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery (c) <a href="https://www.mapbox.com/">Mapbox</a>';
export const mapboxApiUrl = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}';
export const mapboxApiOptions = {
  attribution: mapboxAttribution,
  id: 'mapbox/streets-v11',
  accessToken: mapboxToken,
};

export const provinces = {
  GP: 'Gauteng',
  KZN: 'Kwazulu-Natal',
  WC: 'Western Cape',
  EC: 'Eastern Cape',
  NC: 'Northern Cape',
  MP: 'Mpumalanga',
  FS: 'Free State',
  NW: 'North West',
  L: 'Limpopo',
};

// READ client json CREATE entries
export const addressMapping = {
  address: {
    addressDescription: 'description',
    building: 'address3',
    streetNumber: 'address4',
    streetName: 'address5',
    suburb: 'address6',
    city: 'address7',
    postalCode: 'address8',
    province: 'provinceCode',
    latitude: 'latitude',
    longitude: 'longitude',
  },
};

export const userMapping = {
  user: {
    password: 'password',
    passwordHint: 'passwordHint',
    employeeSAPNumber: 'employeeNumber',
    OTP: 'otp',
  },
};

// READ client json CREATE entries
export const newProfileMapping = {
  name: 'description',
  profileCustomerType: 'customerType',
  accountNumber: 'code',
  meterNumber: 'custom3',
  ...userMapping,
  contactPerson: 'contactPerson',
  mobileNumber: 'mobileNumber',
  homeNumber: 'homeNumber',
  emailAddress: 'emailAddress',
  optOutPreferences: 'optOutSMSCategories',
  officeNumber: 'officeNumber',
  ...addressMapping,
  billingAddress: {
    country: 'country',
  },
};
